import Head from "next/head";

import { urlToMedia } from "../lib/content";

export default function ThumbnailPicture({ thumbnail, width, height, className, alt, preload, lazy }) {

    if (!thumbnail) {
        return null;
    }

    const srcSetJPEG = `${urlToMedia(thumbnail.thumbnail)} 1x, ${urlToMedia(thumbnail.thumbnail2x)} 2x`;
    const srcSetWEBP = `${urlToMedia(thumbnail.thumbnail_webp)} 1x, ${urlToMedia(thumbnail.thumbnail2x_webp)} 2x`;

    return (
        <>
            {preload && (
                <Head>
                    <link rel="preload" as="image" href={urlToMedia(thumbnail.thumbnail)} srcSet={srcSetWEBP} />
                </Head>
            )}
            <picture>
                <source type="image/webp" srcSet={srcSetWEBP} />
                <source type="image/jpeg" srcSet={srcSetJPEG} />
                <img
                    src={urlToMedia(thumbnail.thumbnail)}
                    srcSet={srcSetJPEG}
                    width={width}
                    height={height}
                    alt={alt}
                    className={className}
                    loading={lazy ? "lazy" : "eager"}
                />
            </picture>
        </>
    );
}
